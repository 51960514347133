import React from 'react';
import altImage from '../../assets/blogImage/altImage.png';
import chatBot from '../../assets/blogImage/chatBot.png';
import buttonAriaLabel from '../../assets/blogImage/buttonAriaLabel.png';
import etiquetaSmall from '../../assets/blogImage/etiquetaSmall.png';
import buttonHeader from '../../assets/blogImage/buttonHeader.png';
import efectoAislamiento from '../../assets/blogImage/efectoAislamiento.png';
import porcentajePortfolio from '../../assets/blogImage/porcentajePortfolio.jpeg';
import despeglabeCheckbox from '../../assets/blogImage/desplegableCheckbox.png';
import encabezadosWeb from '../../assets/blogImage/encabezadosWeb.png';
import hedearSeccion from '../../assets/blogImage/hedearSeccion.png';

const blogPosts = [
  {
    id: 1,
    title: "Descripción de imágenes",
    tags: ["Programación"],
    description: "En ocasiones, podemos olvidarnos de la importancia del texto alternativo para las imágenes en nuestro sitio web, o bien, podemos caer en la trampa de usar descripciones muy generales como 'imagen', 'gráfico', 'foto' o 'ícono'. Es fundamental proporcionar descripciones más específicas que realmente reflejen el contenido y la función de la imagen para mejorar la accesibilidad.",
    imageUrl: altImage,
  },
  {
    id: 2,
    title: "Hablemos sobre los ChatBots",
    tags: ["Diseño UX", "Diseño UI"],
    description: "Evita interrumpir al usuario con ChatBot. Abrir un cuadro de diálogo sin que el usuario haya hecho nada es una mala idea y simplemente molesto. Ten en cuenta cuán invasivo es cuando el usuario haga clic en el chatbot.",
    imageUrl: chatBot,
  },
  {
    id: 3,
    title: "Accesibilidad web",
    tags: ["Programación"],
    description: "Cuando uses un elemento <button> o <a>, y este contenga un ícono o imagen, es importante agregar el atributo aria-label con un texto breve y descriptivo.",
    imageUrl: buttonAriaLabel,
  },
  {
    id: 4,
    title: "Guiar al usuario con etiquetas pequeñas",
    tags: ["Diseño UX"],
    description: "En ciertas ocasiones, es útil ofrecer información adicional sobre lo que ocurrirá tras realizar una acción. Esto no solo brinda confianza, sino que también enriquece la experiencia del usuario. Implementar la etiqueta <small></small> es una buena opción.",
    imageUrl: etiquetaSmall,
  },
  {
    id: 5,
    title: "La Ley de Jakob",
    tags: ["Diseño UX", "Diseño UI"],
    description: "Diseñas siguiendo los patrones. La Ley de Jakob: 'los usuarios prefieren productos familiares y funcionales. Cuando algo conocido no está en su lugar, sentimos rechazo o frustración'.",
    imageUrl: buttonHeader,
  },
  {
    id: 6,
    title: "Ley de Von Restorff",
    tags: ["Diseño UX", "Diseño UI"],
    description: "Cuando presentamos una lista de elementos de una misma categoría junto con uno que difiere en algún aspecto (ya sea en tamaño, forma, color, etc.), este último será más memorable y captará mayor atención. En este ejemplo vemos cómo el último elemento tiene más listas, por lo tanto, debería ser más memorable.",
    imageUrl: efectoAislamiento,
  },
  {
    id: 7,
    title: "Porcentaje de portfolio",
    tags: ["Portfolio"],
    description: "En vez de emplear porcentajes vagos y subjetivos, podrías presentar los logotipos de las tecnologías que utilizas, seguidos de un nombre que los describa.",
    imageUrl: porcentajePortfolio,
  },
  {
    id: 8,
    title: "Desplegable checkbox",
    tags: ["Formularios"],
    description: "Muchas veces es mejor usar botones de selección (radio buttons) en lugar de menús desplegables. Los botones de selección proporcionan una forma clara y rápida de elegir para los usuarios, simplificando el proceso y acelerando la toma de decisiones.",
    imageUrl: despeglabeCheckbox,
  },
  {
    id: 9,
    title: "Encabezados web",
    tags: ["Programación"],
    description: "Los encabezados (headers) son fundamentales para estructurar el contenido de una página web. Utiliza encabezados para dividir el contenido en secciones lógicas y mejorar la legibilidad. Además, los encabezados ayudan a los usuarios a entender la estructura del sitio y a navegar por él. Es recomendable usar un h1 y luego dividirlos en h2 y h3.",
    imageUrl: encabezadosWeb,
  },
  {
    id: 10,
    title: "Secciones web",
    tags: ["Programación"],
    description: "Aunque los carruseles pueden parecer visualmente atractivos en términos de diseño, su desempeño en cuanto a la experiencia del usuario no es tan favorable: suelen tener bajas tasas de interacción, pueden resultar molestos y distraer la atención cuando se desplazan de forma automática.",
    imageUrl: hedearSeccion,
  }
  // Agrega más objetos de publicaciones de blog aquí según sea necesario
];


const BlogCard = ({ post }) => {
    const formatDescription = (description) => {
      return description.split('. ').map((sentence, index, array) => (
        <React.Fragment key={index}>
          {sentence}
          {index < array.length - 1 && '. '}
          {index < array.length - 1 && <br />}
        </React.Fragment>
      ));
    };
  
    return (
      <div className="flex flex-col md:flex-row items-start md:space-x-4 mb-8 bg-white rounded-lg shadow-md overflow-hidden">
        {/* Image Section */}
        <div className="w-full md:w-1/3 h-64 md:h-auto">
          <img
            src={post.imageUrl}
            alt={post.title}
            title={post.title}
            className="object-cover w-full h-full"
          />
        </div>
  
        {/* Text Section */}
        <div className="w-full md:w-2/3 p-4 flex flex-col justify-between">
          <div>
            <h2 className="text-black text-2xl font-bold mb-2">{post.title}</h2>
            <div className="text-pink-500 space-x-2 mb-2">
              {post.tags.map((tag, index) => (
                <span key={index} className="inline-block bg-pink-100 rounded-full px-3 py-1 text-sm font-semibold text-pink-500 mr-2 mb-2">
                  {tag}
                </span>
              ))}
            </div>
            <p className="text-gray-600 mb-4">{formatDescription(post.description)}</p>
          </div>
        </div>
      </div>
    );
  };
  
  const WebDesignTips = () => {
    return (
      <div className="max-w-6xl mx-auto p-4 space-y-8">
        <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">10 Consejos Esenciales para el Diseño Web Moderno</h1>
        {blogPosts.map((post) => (
          <BlogCard key={post.id} post={post} />
        ))}
      </div>
    );
  };

export default WebDesignTips;
