import React from 'react';
import videoCourseBasico from '../../assets/videoCourse/videoCourseBasico.mov';
import videoCourseAvanzado from '../../assets/videoCourse/videoCourseAvanzado.mov';
import mentoriasPrivadas from '../../assets/videoCourse/mentoriasPrivadas.mov';

const getVideoSource = (courseData) => {
  switch (courseData) {
    case 'Basico':
      return videoCourseBasico;
    case 'Avanzado':
      return videoCourseAvanzado;
    case 'Mentorias Privadas':
      return mentoriasPrivadas;
    default:
      return videoCourseBasico; // Default to basic course video
  }
};

const VideoCourse = ({ courseData }) => {
  const videoSource = getVideoSource(courseData);

  return (
    <div className="my-6">
      <h2 className="text-xl font-semibold mb-4">Video del Curso</h2>
      <div className="video-container">
        <video 
          className="w-full h-64 md:h-96" 
          controls 
          title={`Video del Curso ${courseData}`}
          width="100%"
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoCourse;
