import React from 'react';
import projectsPersonal from '../utils/projectsPersonal'
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';


export default function ProyectPortfolio() {
  const selectedProject = projectsPersonal.slice(0,3);
  const handleSendProyect = (event) => {
    TagManager.dataLayer({
      dataLayer: {
        event: event,
        title: event 
      }
    });
  }
  return (
    <section className="bg-gradient-to-r from-slate-500 to-slate-800" id='Proyectos'>
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-4xl font-bold tracking-tight text-black-900 sm:text-6xl">
          Proyectos que podés construir como <span class="text-lime-300 filter">Programador</span>
        </h1>

        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-3">
          {selectedProject.map(({ image, title, subtitle, githubCode, liveDemo, youtubeLink }, index) => (
            <div key={index}>
              <img
                className="object-cover w-full rounded-lg h-96"
                src={image}
                alt={title}
                title={title}
              />
              <h2 className="mt-4 text-xl font-semibold text-lime-200 dark:text-white">
                {title}
              </h2>
              <p className="mt-2 text-lg tracking-wider text-black-900 dark:text-black-400">
                {subtitle}
              </p>
              <div className="flex mt-4">
                <a
                  href={githubCode}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center w-1/2 px-4 py-2 mr-2 text-sm font-medium text-white bg-gray-800 rounded-lg hover:bg-gray-700"
                >
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 0C5.372 0 0 5.373 0 12a12 12 0 008.207 11.384c.6.112.82-.26.82-.577v-2.03c-3.34.726-4.042-1.61-4.042-1.61-.546-1.384-1.333-1.753-1.333-1.753-1.09-.744.082-.728.082-.728 1.205.084 1.838 1.238 1.838 1.238 1.07 1.836 2.805 1.305 3.49.997.107-.775.42-1.305.763-1.605-2.665-.303-5.467-1.332-5.467-5.931 0-1.31.468-2.38 1.236-3.22-.123-.303-.536-1.522.117-3.176 0 0 1.01-.322 3.3 1.23a11.37 11.37 0 016.008 0c2.29-1.552 3.298-1.23 3.298-1.23.655 1.654.243 2.873.12 3.176.77.84 1.235 1.91 1.235 3.22 0 4.61-2.806 5.625-5.478 5.922.431.372.817 1.106.817 2.226v3.305c0 .319.217.693.824.576A12 12 0 0024 12c0-6.627-5.373-12-12-12z" />
                  </svg>
                  GitHub
                </a>
                {liveDemo && (
                  <a
                    href={liveDemo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center w-1/2 px-4 py-2 ml-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-500"
                  >
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 0C5.372 0 0 5.373 0 12a12 12 0 008.207 11.384c.6.112.82-.26.82-.577v-2.03c-3.34.726-4.042-1.61-4.042-1.61-.546-1.384-1.333-1.753-1.333-1.753-1.09-.744.082-.728.082-.728 1.205.084 1.838 1.238 1.838 1.238 1.07 1.836 2.805 1.305 3.49.997.107-.775.42-1.305.763-1.605-2.665-.303-5.467-1.332-5.467-5.931 0-1.31.468-2.38 1.236-3.22-.123-.303-.536-1.522.117-3.176 0 0 1.01-.322 3.3 1.23a11.37 11.37 0 016.008 0c2.29-1.552 3.298-1.23 3.298-1.23.655 1.654.243 2.873.12 3.176.77.84 1.235 1.91 1.235 3.22 0 4.61-2.806 5.625-5.478 5.922.431.372.817 1.106.817 2.226v3.305c0 .319.217.693.824.576A12 12 0 0024 12c0-6.627-5.373-12-12-12z" />
                    </svg>
                    Live Demo
                  </a>
                )}
                {youtubeLink && (
                  <a
                  href={youtubeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center w-1/2 px-4 py-2 ml-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-500"
                  >
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19.615 3.184C18.42 2.723 12 2.723 12 2.723s-6.42 0-7.615.461C3.615 3.618 3 5.354 3 5.354s-.615 2.36-.615 4.723v2.846c0 2.363.615 4.723.615 4.723s.615 1.736 1.385 2.17c1.195.461 7.615.461 7.615.461s6.42 0 7.615-.461c.77-.434 1.385-2.17 1.385-2.17s.615-2.36.615-4.723V10.08c0-2.363-.615-4.723-.615-4.723s-.615-1.736-1.385-2.173zM9.545 15.444V8.59l5.545 3.427-5.545 3.427z" />
                    </svg>
                    Youtube
                  </a>
                )}
                
              </div>
            </div>
          ))}
        </div>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
          target='_blank'
          rel='noreferrer'
          to={`/personal-portfolio/`}  
          onClick={() => handleSendProyect('discoverMoreProjects')}
          className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            Descubre Más
          </Link>
        </div>
      </div>

    </section>
  );
}
