import { Link } from 'react-router-dom';
import logoIcon from '../assets/logoIcon.png';

function NavbarViews() {
  return (
    <div className="fixed top-0 left-0 w-full z-50 dark:bg-gray-900 border-b backdrop-blur-lg bg-opacity-80">
      <div className="mx-auto max-w-7xl px-6 sm:px-6 lg:px-8">
        <div className="relative flex h-16 justify-between">
          <div className="flex flex-1 items-stretch justify-start">
            <Link to="/" className="flex flex-shrink-0 items-center">
              <img
                className="block h-12 w-auto"
                src={logoIcon}
                alt="titulo del logo"
                title="Al hacer click te lleva a la pagina principal"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavbarViews;