import { questionsQuizz } from "./questionsQuizz";

export default function FaqQuiz() {
  return (
    <div className="mb-10">
      <h2 className="text-3xl font-bold text-gray-800 tracking-widest uppercase text-center mt-20 mb-10">
        22 Preguntas Javascript Avanzado
      </h2>

      {/* Grid de 3 columnas */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {questionsQuizz.map((question) => (
          <div key={question.id} className="p-4 border rounded-lg">
            {/* Pregunta */}
            <h3 className="text-lg font-semibold mb-2">{question.question}</h3>

            {/* Justificación */}
            <p className="mt-3 line-clamp-3 text-sm leading-6 text-gray-600 dark:text-gray-400">
              {question.justification}
            </p>

            {/* Opciones */}
            <div className="space-y-2 mt-2">
              {question.options.map((option, index) => (
                <label
                  key={index}
                  className={`block ${
                    option === question.correctAnswer
                      ? "text-green-600 font-bold"
                      : ""
                  }`}
                >
                  <input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option}
                    checked={option === question.correctAnswer} // Marca la respuesta correcta
                    disabled // Deshabilitado para que no pueda modificarse
                    className="mr-2"
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
