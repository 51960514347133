import React from 'react';
import Chapter from './Chapter';

const CourseContent = ({ content }) => {
  return (
    <div className="mt-6">
      <h3 className="text-lg font-semibold mb-4">Contenido del curso</h3>
      <p className="text-sm mb-4">
        {content.sections} secciones • {content.classes} clases • {content.duration} de duración total
      </p>
      {content.chapters.map((chapter, index) => (
        <Chapter key={index} chapter={chapter} />
      ))}
    </div>
  );
};

export default CourseContent;