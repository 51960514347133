import React from 'react';

export default function Footer() {
    return (
        <footer className="flex flex-col space-y-10 justify-center  bg-gray-900 text-white py-12" id='Contacto'>
            <nav className="flex justify-center flex-wrap gap-6 text-gray-300 font-medium">
                <a className="hover:text-white" href="#Proyectos">Proyectos</a>
                <a className="hover:text-white" href="#Testimonios">Testimonios</a>
                <a className="hover:text-white" href="#Cursos">Curso</a>
                <a className="hover:text-white" href="#Blog">Blog</a>
                <a className="hover:text-white" href="#PreguntaFrecuentes">Preguntas Frecuentes</a>
            </nav>

            <div className="flex justify-center space-x-5">
                <a href="https://www.youtube.com/@educacionCristian" target="_blank" rel="noopener noreferrer">
                    <img src="https://img.icons8.com/fluent/30/000000/youtube-play.png" alt="YouTube" title='Cristian Cabrera canal de YouTube'/>
                </a>
                <a href="https://www.linkedin.com/in/cristian-cabrera-304500183/" target="_blank" rel="noopener noreferrer">
                    <img src="https://img.icons8.com/fluent/30/000000/linkedin-2.png" alt="LinkedIn" title='Cristian Cabrera perfil de LinkedIn'/>
                </a>
                <a href="https://www.instagram.com/educacioncristian/" target="_blank" rel="noopener noreferrer">
                    <img src="https://img.icons8.com/fluent/30/000000/instagram-new.png" alt="Instagram" title='Cristian Cabrera perfil de Instagram'/>
                </a>
                <a href="https://x.com/crcristian97" target="_blank" rel="noopener noreferrer">
                    <img src="https://img.icons8.com/fluent/30/000000/twitter.png" alt="Twitter" title='Cristian Cabrera perfil de Twitter'/>
                </a>
                <a href="https://github.com/crcristian97" target="_blank" rel="noopener noreferrer">
                    <img src="https://img.icons8.com/fluent/30/000000/github.png" alt="GitHub" title='Cristian Cabrera perfil de GitHub'/>
                </a>
                <a href="https://www.tiktok.com/@educacion.cristian" target="_blank" rel="noopener noreferrer">
                    <img src="https://img.icons8.com/fluent/30/000000/tiktok.png" alt="TikTok" title='Cristian Cabrera perfil de TikTok'/>
                </a>
            </div>

            <div className="text-center">
                <p className="text-gray-300 font-medium mb-2">&copy; 2023 TechAcademy. Todos los derechos reservados.</p>
                <p className="text-sm text-gray-400">¿Tienes consultas? Contáctame directamente:</p>
                <a href="mailto:crcristian97.cc@gmail.com" className="text-blue-400 hover:underline">
                    crcristian97.cc@gmail.com
                </a>
            </div>
        </footer>
    );
};