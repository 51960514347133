import React from 'react';

const LearningObjectives = ({ objectives }) => {
  return (
    <div className="mt-6 bg-box border border-line rounded-3xl p-5">
      <h3 className="text-lg font-semibold mb-4">Lo que aprenderás</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {objectives.map((objective, index) => (
          <div key={index} className="text-sm">{objective}</div>
        ))}
      </div>
    </div>
  );
};

export default LearningObjectives;