import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TemarioCourses from '../components/CourseInfoTemplate/TemarioCourses';

// Import JSON data
import jsBasicoData from '../data/jsBasicoData.json';
import jsAvanzadoData from '../data/jsAvanzadoData.json';
import mentoriasPrivadasData from '../data/mentoriasPrivadasData.json';

const DescriptionCurso = () => {
  const [courseData, setCourseData] = useState(null);
  const { title } = useParams();

  useEffect(() => {
    switch(title) {
      case 'Javascript Basico':
        setCourseData(jsBasicoData);
        break;
      case 'Javascript Avanzado':
        setCourseData(jsAvanzadoData);
        break;
      case 'Mentorias Privadas':
        setCourseData(mentoriasPrivadasData);
        break;
      default:
        setCourseData(null);
    }
  }, [title]);

  return (
    <>
      {courseData ? (
        <TemarioCourses courseData={courseData} />
      ) : (
        <p>No se encontró información para el curso: {title}</p>
      )}
    </>
  );
};

export default DescriptionCurso;