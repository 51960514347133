import React from 'react'
import Footer from './Footer'
import { Analytics } from '@vercel/analytics/react';

const Layout = ( {children} ) => {
  return (
    <>
    <Analytics />
    {children}
    <Footer />
    </>
  )
}

export default Layout