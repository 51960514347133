import React from 'react';
import FuturiaHomeSeccion from '../../assets/Futuria/FuturiaHomeSeccion.png';

const HeroFuturia = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#0B0B2B] via-[#1A1A4F] to-[#2D1B4E]">
      {/* Navigation */}
      

      {/* Hero Section */}
      <main className="container mx-auto px-6 py-16 grid md:grid-cols-2 gap-12 items-center">
      <div className="space-y-8">
    <h1 className="text-6xl font-bold text-white leading-tight tracking-wider font-orbitron">
        ARTIFICIAL<br />INTELLIGENCE
      </h1>
      <p className="text-gray-300 text-lg max-w-xl font-orbitron mb-12"> {/* Agregado mb-12 */}
      En un mundo donde la inteligencia artificial es esencial, aquí tienes herramientas que pueden impulsar tu éxito como emprendedor. Aprovecha la tecnología para optimizar procesos, generar ideas innovadoras y mejorar tu productividad, asegurando un futuro laboral competitivo y eficiente.
      </p>
      <a href="#herramientas" className="inline-block px-8 py-3 bg-transparent border-2 border-[#4DFFA7] text-[#4DFFA7] rounded-md hover:bg-[#4DFFA7] hover:text-[#0B0B2B] transition-all duration-300 font-orbitron">
        EXPLORE MORE
      </a>
    </div>  

        {/* Placeholder for your image */}
        <div className="relative">
          <div className="absolute -inset-4 bg-gradient-to-r from-[#FF3366] via-[#4DFFA7] to-[#33CCFF] opacity-30 blur-xl rounded-full"></div>
          <div className="relative aspect-square rounded-full bg-gradient-to-br from-[#1A1A4F] to-[#2D1B4E] flex items-center justify-center">
            {/* Add your image here */}
            <img src={FuturiaHomeSeccion} alt="Futuria Home Seccion" title='Robot futurista combinado con IA' className=" h-full object-cover" />
          </div>
        </div>
      </main>

      {/* Footer Navigation */}
      

      {/* Social Icons */}
      <div className="fixed bottom-8 right-8">

      </div>
    </div>
  );
}

export default HeroFuturia;