import React, { useState } from 'react';

const FAQ = () => {
  const faqData = [
    {
      question: "¿Cómo accedo al contenido del curso?",
      answer: "El contenido del curso se encuentra gratis en la plataforma."
    },
    {
      question: "¿Para que es la suscripción a Kick?",
      answer: "La suscripción a Kick es opcional. Si la adquieres, podes charlar conmigo sobre las novedades tecnológicas y programación."
    },
    {
      question: "¿Se actualiza el contenido?",
      answer: "Si, el contenido se actualiza constantemente para que estés al tanto de las últimas novedades tecnológicas."
    },
    {
      question: "¿Puedo colaborar?",
      answer: "Si, mandame un mensaje y te comento cómo podes ayudar con el blog"
    },
    {
      question: "¿Cual es el objetivo de la academia?",
      answer: "El objetivo de la academia es que aprendas a programar de manera autodidacta y efectiva, colaborando con la comunidad."
    }
];


  const [openItem, setOpenItem] = useState(null);

  return (
    <section className="bg-gradient-to-r from-slate-500 to-slate-800 py-12" id='PreguntaFrecuentes'>
    <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-6xl font-bold text-center mb-8">Preguntas Frecuentes</h1>
        <div className="shadow-lg bg-white rounded-xl">
            {faqData.map((item, index) => (
                <div key={index} className="border-b">
                    <button
                        className="flex items-center justify-between w-full p-4 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition-colors"
                        onClick={() => setOpenItem(openItem === index ? null : index)}
                    >
                        <span className="text-lg font-medium">{item.question}</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`h-6 w-6 transform transition-transform ${openItem === index ? 'rotate-180' : ''}`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </button>
                    {openItem === index && (
                        <div className="p-4 text-gray-700 border-t">{item.answer}</div>
                    )}
                </div>
            ))}
        </div>
    </div>
</section>
  );
};

export default FAQ;