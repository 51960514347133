import React from 'react';
import { CodeBracketIcon } from '@heroicons/react/24/outline';
import { FaTwitter, FaYoutube, FaTiktok, FaInstagram, FaGithub, FaLinkedin } from 'react-icons/fa';
import imgProfile from '../../assets/1578750284142.webp';

const TeachInfo = () => {
  return (
    <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Docente</h2>
      <div className="flex items-center mb-4">
        <img
          src={imgProfile}
          alt="Cristian Cabrera"
          className="w-16 h-16 rounded-full mr-4"
        />
        <div>
          <h3 className="text-xl font-semibold text-green-400">Cristian Cabrera</h3>
          <p className="text-gray-400">Creador de Contenido</p>
        </div>
      </div>
      <p className="mb-4">
        Programación JavaScript y Desarrollo Web.
      </p>
      <div className="flex items-center mb-4">
        <CodeBracketIcon className="w-5 h-5 text-gray-400 mr-2" />
        <p>+4 años de experiencia como Ingeniero de Software</p>
      </div>
      <div className="flex space-x-4">
        <a href="https://x.com/crcristian97" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="w-6 h-6 text-gray-400 hover:text-white cursor-pointer" />
        </a>
        <a href="https://www.youtube.com/@educacionCristian" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="w-6 h-6 text-gray-400 hover:text-white cursor-pointer" />
        </a>
        <a href="https://www.tiktok.com/@educacion.cristian" target="_blank" rel="noopener noreferrer">
            <FaTiktok className="w-6 h-6 text-gray-400 hover:text-white cursor-pointer" />
        </a>
        <a href="https://www.instagram.com/educacioncristian/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="w-6 h-6 text-gray-400 hover:text-white cursor-pointer" />
        </a>
        <a href="https://github.com/crcristian97" target="_blank" rel="noopener noreferrer">
            <FaGithub className="w-6 h-6 text-gray-400 hover:text-white cursor-pointer" />
        </a>
        <a href="https://www.linkedin.com/in/cristian-cabrera-304500183/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="w-6 h-6 text-gray-400 hover:text-white cursor-pointer" />
        </a>
      </div>
    </div>
  );
};

export default TeachInfo;