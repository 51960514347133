import React from 'react';
import { usePredictiveSearch } from '../views/hooks/usePredictiveSearch';

const Search = ({ onSearchResult }) => {
  const { 
    searchTerm, 
    suggestions, 
    showSuggestions,
    handleSearch, 
    handleSuggestionClick,
    handleInputFocus,
    handleInputBlur
  } = usePredictiveSearch(onSearchResult);

  return (
    <div className="mb-8 relative">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder="Introduce aquí tu pregunta sobre JavaScript"
        className="w-full p-4 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 shadow-lg">
          {suggestions.map((faq, index) => (
            <li 
              key={index} 
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onMouseDown={() => handleSuggestionClick(faq.question)}
            >
              {faq.question}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Search;