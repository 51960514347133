import React from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'


const callouts = [
  {
    name: 'Coding',
    description: 'Como subir tu proyecto a github',
    youtubeLink: '1kbcdzGoweo',
  },
  {
    name: 'Coding',
    description: 'Integrar mercado pago con HTML y JAVASCRIPT',
    youtubeLink: 'vuxjoe2xZqs',
  },
  {
    name: 'Coding',
    description: 'Carrito de compra',
    youtubeLink: 'Eh9LhlfgCME',
  },
];

export default function CattegoryShow() {
  return (
    <div className="bg-gradient-to-r from-slate-500 to-slate-800">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <h2 className="text-4xl font-bold tracking-tight text-black-900 sm:text-6xl">Mira lo que dicen nuestros profesionales</h2>

          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {callouts.map((callout, index) => (
              <div key={index} className="group relative">
                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                  <LiteYouTubeEmbed
                    id={callout.youtubeLink}
                    title={callout.name}
                    poster="maxresdefault" // Optional: selects the thumbnail quality
                    webp // Optional: load webp image format if supported
                    noCookie // Optional: if you want to use the "nocookie" domain
                  />
                </div>
                <h3 className="mt-4 text-xl font-semibold text-lime-200 dark:text-white">
                  <a href={`https://www.youtube.com/watch?v=${callout.youtubeLink}`} target="_blank" rel="noopener noreferrer">
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-xl font-semibold text-gray-900">{callout.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
