export const questionsQuizz = [
  {
    id: 1,
    question: "El objeto history contiene las URL visitadas por el usuario. Entre los métodos de este objeto, ¿cuál es la utilidad del método go()?",
    justification: "El método go() permite a los desarrolladores de JavaScript controlar la navegación del usuario en el historial de navegación del navegador. Esto es útil para implementar funcionalidades como la navegación hacia adelante y hacia atrás, así como para controlar la posición del historial de navegación.",
    options: ["Redirige a una página determinada", "Ninguna de las alternativas", "Actualiza la página", "Vuelve a la página visitada previamente"],
    correctAnswer: "Vuelve a la página visitada previamente"
  },
  {
    id: 2,
    question: "La función que se utiliza para enviar una petición AJAX es la función...",
    justification: "La función send() es utilizada para enviar una petición AJAX. Esta función envía una solicitud al servidor y espera una respuesta. Es comúnmente utilizada en aplicaciones web modernas para actualizar partes de una página web sin necesidad de recargar la página completa.",
    options: ["send()", "sends()", "exec()", "go()"],
    correctAnswer: "send()"
  },
  {
    id: 3,
    question: "La palabra `new` es una palabra reservada en JavaScript?",
    justification: "La palabra `new` es una palabra reservada en JavaScript, lo que significa que no se puede utilizar como nombre de variable, función, objeto, etc.",
    options: ["Verdadero", "Falso"],
    correctAnswer: "Verdadero"
  },
  {
    id: 4,
    question: "La función getDay(), que se utiliza para trabajar con fechas, sirve para...",
    justification: "La función getDay() devuelve el día de la semana de la fecha especificada en función de la fecha local; siendo 0 (Domingo) el primer día.",
    options: ["Devolver el día de la semana (0-6)", "Indicar si se trata de una fecha válida (true o false)", "Indicar el día de una fecha (1 a 31)", "Ninguna de las alternativas"],
    correctAnswer: "Devolver el día de la semana (0-6)"
  },
  {
    id: 5,
    question: "La sintaxis correcta para crear un objeto XMLHttpRequest es:",
    justification: "La sintaxis correcta para crear un objeto XMLHttpRequest es utilizando la palabra reservada `new`. Ejemplo: const xhr = new XMLHttpRequest();",
    options: ["new HttpRequest();", "new XMLRequest();", "XMLHttpRequest();", "new XMLHttpRequest();"],
    correctAnswer: "new XMLHttpRequest();"
  },
  {
    id: 6,
    question: `Si se declara una función llamada "soma()" no es posible acceder a una variable llamada "soma" declarada anteriormente:`,
    justification: "En JavaScript, las variables declaradas dentro de una función no son accesibles desde fuera de la función. Por lo tanto, si se declara una función con el nombre 'soma', no se puede acceder a una variable global o declarada fuera de la función con el mismo nombre.",
    options: ["Verdadero", "Falso"],
    correctAnswer: "Falso"
  },
  {
    id: 7,
    question: `La función round() tiene la finalidad de redondear los números al número más cercano. ¿A que tipo corresponde el valor devuelto?`,
    justification: "La función round() devuelve un valor numérico, correspondiente al número más cercano.",
    options: ["Esta función no existe", "Float", "Integer", "Decimal"],
    correctAnswer: "Integer"
  },
  
  {
    id: 8,
    question: `El ciclo de vida de una variable local termina cuando la función en la que está es completada`,
    justification: "En JavaScript, una variable local declarada dentro de una función solo existe dentro del ámbito (scope) de esa función. Una vez que la ejecución de la función se completa, las variables locales se eliminan y su ciclo de vida termina.",
    options: ["Verdadero", "Falso"],
    correctAnswer: "Verdadero"
  },
  
  {
    id: 9,
    question: `El término que se utiliza cuando una variable no ha recibido ningún valor es: `,
    justification: "El término que se utiliza cuando una variable no ha recibido ningún valor es 'undefined'.",
    options: ["undeclared", "zerovalue", "undefined", "novalue"],
    correctAnswer: "undefined"
  },
  {
    id: 10,
    question: `NaN es una palabra reservada en JavaScript que indica que el valor de una variable no es un número:`,
    justification: "NaN es una palabra reservada en JavaScript que indica que el valor de una variable no es un número.",
    options: ["Verdadero", "Falso"],
    correctAnswer: "Verdadero"
  },
  {
    id: 11,
    question: `¿Qué función presente en JavaScript devuelve la raíz cuadrada de un número?`,
    justification: "La función sqrt() devuelve la raíz cuadrada de un número.",
    options: ["sqrt()", "pop()", "quad()"],
    correctAnswer: "sqrt()"
  },
  {
    id: 12,
    question: `El ciclo de vida de una variable global termina cuando se cierra la página:`,
    justification: "El ciclo de vida de una variable global en JavaScript termina cuando se cierra la página o se recarga. Las variables globales se mantienen en la memoria mientras la página esté abierta, pero se eliminan una vez que la página se cierra.",
    options: ["Verdadero", "Falso"],
    correctAnswer: "Verdadero"
  },
  {
    id: 13,
    question: `Considerando la expresión var a = 3<<2, el valor de ‘a’ es:`,
    justification: "La expresión var a = 3<<2 es una operación de desplazamiento de bits en JavaScript. El operador << es el operador de desplazamiento a la izquierda. En este caso, el número 3 se desplaza 2 bits a la izquierda. El resultado de esta operación es 12.",
    options: ["12", "3", "2", "1"],
    correctAnswer: "12"
  },
  {
    id: 14,
    question: `El operador responsable de devolver el resto de una división es:`,
    options: ["$", "/", "%", "&&"],
    correctAnswer: "%"
  },
  {
    id: 15,
    question: `La función parseInt() tiene como objetivo:`,
    justification: "La función parseInt() convierte una cadena (string) en un número entero.",
    options: ["Leer un número entero y devolver un string", "Convertir el valor de una variable en un número entero", "Leer un número entero y devolver el número siguiente", "Convertir un número entero en un string"],
    correctAnswer: "Convertir el valor de una variable en un número entero"
  },
  {
    id: 16,
    question: `La función que se utiliza para eliminar los espacios en blanco al principio y al final de un string es`,
    justification: "La función trim() elimina los espacios en blanco al principio y al final de un string.",
    options: ["clear", "trim", "cut"],
    correctAnswer: "trim"
  },
  {
    id: 17,
    question: `JavaScript es un lenguaje de programación`,
    justification: "JavaScript es un lenguaje de programación interpretado, lo que significa que el código JavaScript se ejecuta directamente en el navegador o en un entorno de servidor sin la necesidad de compilarlo en un formato binario.",
    options: ["Interpretado", "Compilado", "Interpretado y compilado", "Ninguna de las alternativas"],
    correctAnswer: "Interpretado"
  },
  {
    id: 18,
    question: `Es posible, mediante javascript, hacer cambios de estilo (CSS) en los elementos:`,
    options: ["Verdadero", "Falso"],
    correctAnswer: "Verdadero"
  },
  {
    id: 19,
    question: `El operador '-' además de ser responsable de la sustracción, también puede convertir un número en negativo:`,
    justification: "El operador '-' puede utilizarse para realizar la operación de sustracción, pero también puede utilizarse para convertir un número en su negativo. Por ejemplo, -5 es el negativo de 5.",
    options: ["Verdadero", "Falso"],
    correctAnswer: "Verdadero"
  },
  {
    id: 20,
    question: `En cuanto a la función charAtPosition(), seleccione la alternativa correcta: `,
    justification: "La función charAtPosition() devuelve el carácter en la posición especificada de una cadena.",
    options: ["Devuelve el carácter en la posición especificada", "Devuelve la posición del carácter en la cadena", "Devuelve la longitud de la cadena", "Ninguna de las alternativas"],
    correctAnswer: "Devuelve el carácter en la posición especificada"
  },
  {
    id: 21,
    question: `¿Qué sucede si declaras dos variables let con el mismo nombre en el mismo bloque de código?`,
    justification: "Si declaras dos variables let con el mismo nombre en el mismo bloque de código, se lanzará un error de sintaxis.",
    options: ["Sobrescribe la primera variable.", "Lanza un error de sintaxis", "Ambas variables coexistirán", "La segunda variable será ignorada"],
    correctAnswer: "Lanza un error de sintaxis"
  },
  {
    id: 22,
    question: `¿Cuál es el resultado de la expresión typeof null en JavaScript?`,
    justification: "En JavaScript, typeof null devuelve 'object'. Este es un error conocido y histórico del lenguaje.",
    options: ["'object'", "'null'", "'undefined'", "'number'"], 
    correctAnswer: "'object'"
  },
];