import React from 'react';

const BlogServerComponent = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12 mt-12 ">
      <header className="mb-12 text-center">
        <h1 className="text-4xl font-bold text-black mb-4 font-['Plus_Jakarta_Sans']">La Distinción entre SSR y Server Components en Next.js: Optimiza el Rendimiento</h1>
        <p className="text-black font-['Merriweather']">13 de Diciembre, 2024</p>
      </header>

      <section className="prose prose-lg max-w-none">
        <p className="text-black mb-6 font-['Merriweather']">
          Cuando se trabaja con Next.js, uno de los marcos más avanzados y populares para aplicaciones React, una de las decisiones clave que se deben tomar es cómo manejar el proceso de renderizado de las aplicaciones web. Específicamente, dos enfoques predominantes son <strong className="font-semibold">Server Side Rendering (SSR)</strong> y <strong className="font-semibold">Server Components (SC)</strong>.
        </p>
        <p className="text-black mb-8 font-['Merriweather']">
          En este artículo, exploramos las diferencias cruciales entre ambos métodos y cómo cada uno impacta en el rendimiento, la interactividad y el uso de recursos.
        </p>

        <h2 className="text-2xl font-bold text-black mt-8 mb-4 font-['Plus_Jakarta_Sans']">1️⃣ Tamaño del Bundle</h2>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SSR:</strong> En SSR, el servidor genera HTML prerenderizado que se envía al cliente junto con un paquete de JavaScript. El JavaScript es responsable de hacer la página interactiva, pero a medida que más componentes interactivos se agregan, el tamaño del bundle puede aumentar.
        </p>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SC:</strong> Los Server Components no envían JavaScript al cliente para las partes renderizadas en el servidor, lo que reduce significativamente el tamaño del bundle y mejora la velocidad de carga en el navegador.
        </p>
        <p className="bg-blue-50 p-4 rounded-lg text-black mb-8 font-['Merriweather']">
          <strong className="font-semibold">Beneficio de SC:</strong> Ideal para páginas donde muchas secciones son estáticas o no requieren interacción, lo que mejora los tiempos de carga y la eficiencia.
        </p>

        <h2 className="text-2xl font-bold text-black mt-8 mb-4 font-['Plus_Jakarta_Sans']">2️⃣ Proceso de Hidratación</h2>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SSR:</strong> Después de que el HTML es renderizado, el cliente realiza un proceso de "hidratación", añadiendo interactividad al HTML cargado. Esto puede ser costoso en términos de rendimiento.
        </p>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SC:</strong> Los Server Components no requieren hidratación, lo que elimina el proceso adicional y mejora la carga en el cliente.
        </p>
        <p className="bg-blue-50 p-4 rounded-lg text-black mb-8 font-['Merriweather']">
          <strong className="font-semibold">Beneficio de SC:</strong> La reducción de la sobrecarga de hidratación mejora los tiempos de carga y el rendimiento general.
        </p>

        <h2 className="text-2xl font-bold text-black mt-8 mb-4 font-['Plus_Jakarta_Sans']">3️⃣ Renderizado del Árbol de Componentes</h2>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SSR:</strong> Todo el árbol de componentes se renderiza en el servidor, y luego se pasa al cliente como HTML.
        </p>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SC:</strong> Permite renderizar sólo las partes necesarias del árbol en el servidor, lo que optimiza el rendimiento y reduce la carga en el cliente.
        </p>
        <p className="bg-blue-50 p-4 rounded-lg text-black mb-8 font-['Merriweather']">
          <strong className="font-semibold">Beneficio de SC:</strong> Permite optimizar la arquitectura de la página, enviando solo lo necesario al cliente.
        </p>

        <h2 className="text-2xl font-bold text-black mt-8 mb-4 font-['Plus_Jakarta_Sans']">4️⃣ Ciclo de Vida del Componente</h2>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SSR:</strong> Los componentes en SSR tienen un ciclo de vida completo en el cliente, como `componentDidMount` o `useEffect`.
        </p>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SC:</strong> Los Server Components no tienen ciclo de vida en el cliente, ya que todo el procesamiento se hace en el servidor.
        </p>
        <p className="bg-blue-50 p-4 rounded-lg text-black mb-8 font-['Merriweather']">
          <strong className="font-semibold">Beneficio de SC:</strong> Eliminar el ciclo de vida en el cliente simplifica el comportamiento de los componentes.
        </p>

        <h2 className="text-2xl font-bold text-black mt-8 mb-4 font-['Plus_Jakarta_Sans']">5️⃣ Obtención de Datos</h2>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SSR:</strong> SSR combina datos estáticos del servidor con datos dinámicos manejados en el cliente, lo que permite interactividad.
        </p>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SC:</strong> Los Server Components trabajan principalmente con datos estáticos, ya que su objetivo es evitar la complejidad del manejo de datos dinámicos en el cliente.
        </p>
        <p className="bg-blue-50 p-4 rounded-lg text-black mb-8 font-['Merriweather']">
          <strong className="font-semibold">Beneficio de SC:</strong> Menor complejidad y sobrecarga en el manejo de datos del lado del cliente.
        </p>

        <h2 className="text-2xl font-bold text-black mt-8 mb-4 font-['Plus_Jakarta_Sans']">¿Cuándo Usar Cada Uno?</h2>
        <p className="text-black mb-4 font-['Merriweather']">
          <strong className="font-semibold">SSR:</strong> Ideal para aplicaciones con alta interactividad y contenido dinámico, como dashboards y redes sociales.
        </p>
        <p className="text-black mb-8 font-['Merriweather']">
          <strong className="font-semibold">SC:</strong> Perfecto para páginas estáticas o con secciones sin interacción, como blogs o catálogos de productos.
        </p>

        <footer className="mt-12 pt-8 border-t border-gray-200 text-center text-black font-['Merriweather']">
          <p>Escrito por un Desarrollador Full Stack | Todos los derechos reservados &copy; 2024</p>
        </footer>
      </section>
    </div>
  );
};

export default BlogServerComponent;
