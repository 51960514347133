import { faqs } from './faqData';

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const filterFaqs = (searchTerm, exact = false) => {
  const normalizedSearchTerm = removeAccents(searchTerm.toLowerCase());
  return faqs.filter(faq => {
    const normalizedQuestion = removeAccents(faq.question.toLowerCase());
    return exact 
      ? normalizedQuestion === normalizedSearchTerm
      : normalizedQuestion.includes(normalizedSearchTerm);
  });
};
export const getInitialFaqs = () => {
  return faqs.slice(0, 6);
};