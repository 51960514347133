export const faqs = [
  {
    question: '¿Cuál es la diferencia entre let, const y var en JavaScript?',
    answer: 'var tiene alcance de función y es redeclarable. let tiene alcance de bloque y no es redeclarable.  const también tiene alcance de bloque y, además, su valor no puede cambiarse una vez asignado.',
  },
  {
    question: '¿Qué son las funciones de orden superior?',
    answer: 'Son funciones que pueden tomar otras funciones como argumentos o devolver funciones como resultado. Ejemplos comunes incluyen map, filter, y reduce',
  },
  {
    question: '¿Cómo manejas errores en JavaScript?',
    answer: 'Se pueden manejar errores usando try...catch para capturar excepciones. También se puede usar throw para generar errores de forma manual.',
  },
  {
    question: '¿Qué es el asincronismo en JavaScript y cómo se maneja?',
    answer: 'El asincronismo permite que el código se ejecute sin bloquear la ejecución de otras tareas. Se maneja con callbacks, promesas (Promise), y la sintaxis async/await.',
  },
  {
    question: '¿Qué es una promesa (Promise) en JavaScript?',
    answer: ' Una promesa es un objeto que representa la eventual finalización (o fracaso) de una operación asincrónica y su valor resultante. Tiene estados como pending, fulfilled, y rejected.',
  },
  {
    question: '¿Qué es la desestructuración (destructuring) y cómo se usa en JavaScript?',
    answer: 'La desestructuración es una sintaxis que permite extraer valores de arrays u objetos y asignarlos a variables. Se puede hacer con arrays, como [a, b] = [1, 2], y con objetos, como {name, age} = {name: "John", age: 30}.',
  },
  {
    question: '¿Qué es la palabra clave this en JavaScript?',
    answer: 'La palabra clave this en JavaScript se refiere al contexto de ejecución actual. Su valor depende de cómo se invoca una función. En el contexto global, this se refiere al objeto global (window en navegadores). En el contexto de un objeto, this se refiere al objeto en sí.',
  },
  {
    question: '¿Cuáles son las diferentes formas de definir una función en JavaScript?',
    answer: `Declaración de función: function myFunction() { ... }
            Expresión de función: const myFunction = function() { ... }
            Función flecha: const myFunction = () => { ... }
            Método de objeto: const obj = { myFunction() { ... } }
            Función de constructor: function MyConstructor() { ... }.`,
  },
  {
    question: '¿Qué son las funciones flecha en JavaScript?',
    answer: ' Las funciones flecha son una forma compacta de escribir funciones en JavaScript introducidas en ES6. Tienen una sintaxis más corta y no vinculan su propio this, arguments, super, o new.target',
  },
  {
    question: '¿Cuál es la diferencia entre forEach y map?',
    answer: 'forEach ejecuta una función sobre cada elemento de un array sin devolver un nuevo array. map también ejecuta una función sobre cada elemento, pero devuelve un nuevo array con los resultados de esa función.',
  },
  {
    question: '¿Cuál es el propósito de la función map en JavaScript?',
    answer: 'El propósito de la función map es crear un nuevo array con los resultados de aplicar una función a cada elemento de un array existente.',
  },
  {
    question: '¿Cuál es el propósito de la función filter en JavaScript?',
    answer: 'El propósito de la función filter es crear un nuevo array con todos los elementos de un array que pasan una prueba especificada por una función.',
  },
  {
    question: '¿Cuál es el propósito de la función reduce en JavaScript?',
    answer: 'El propósito de la función reduce es aplicar una función a un acumulador y a cada valor de un array (de izquierda a derecha) para reducirlo a un solo valor.',
  },
  {
    question: '¿Qué es una función de callback en JavaScript?',
    answer: 'Una función de callback es una función que se pasa como argumento a otra función y se ejecuta después de que ocurra algún evento o se complete alguna operación.',
  },
  {
    question: '¿Qué son los módulos de JavaScript?',
    answer: 'Los módulos de JavaScript son archivos separados que pueden exportar y/o importar partes de código (como variables, funciones, clases) utilizando las declaraciones export e import. Permiten organizar y reutilizar el código de manera más eficiente.',
  },
  {
    question: '¿Qué son las clases de JavaScript?',
    answer: 'Las clases de JavaScript, introducidas en ES6, son una forma de crear objetos y manejar la herencia en JavaScript. Utilizan la palabra clave class y permiten definir métodos y constructor en un solo lugar. Ejemplo: class MyClass { constructor() { ... } method() { ... } }.',
  },
  {
    question: '¿Qué son los getters y setters en JavaScript?',
    answer: 'Los getters y setters son métodos en objetos que permiten definir cómo acceder y actualizar propiedades de un objeto. get se usa para definir un método de acceso y set para definir un método de mutación.',
  },
  {
    question: '¿Qué es el objeto localStorage en JavaScript?',
    answer: 'localStorage es un objeto que permite almacenar datos de manera persistente en el navegador del usuario. Los datos no expiran y persisten incluso después de cerrar el navegador.',
  },
  {
    question: '¿Cuál es la diferencia entre localStorage y las cookies?',
    answer: 'localStorage almacena datos de manera persistente en el navegador y no se envía al servidor con cada solicitud HTTP. Las cookies también almacenan datos en el navegador, pero se envían al servidor con cada solicitud HTTP. Las cookies tienen un tamaño limitado y una fecha de caducidad.',
  },
  {
    question: '¿Cuál es el propósito de la función setTimeout?',
    answer: 'La función setTimeout se usa para ejecutar una función después de un período de tiempo especificado (en milisegundos).',
  },
  {
    question: '¿Cuál es el propósito de la función fetch en JavaScript?',
    answer: 'La función fetch se usa para hacer solicitudes HTTP asíncronas y obtener recursos desde el servidor. Devuelve una promesa que se resuelve con la respuesta de la solicitud.',
  },
  {
    question: '¿Cuál es la diferencia entre null y undefined?',
    answer: 'null es un valor asignado explícitamente que indica la ausencia de cualquier valor. undefined significa que una variable ha sido declarada pero no ha sido asignada ningún valor.',
  },
  {
    question: '¿Cuál es el propósito de addEventListener en JavaScript?',
    answer: 'addEventListener se usa para adjuntar un manejador de eventos a un elemento específico. Permite escuchar eventos específicos (como click, mouseover) y ejecutar una función cuando ocurre el evento.',
  },
  {
    question: '¿Cuál es el propósito del método querySelector en JavaScript?',
    answer: 'El método querySelector se usa para seleccionar el primer elemento que coincida con un selector CSS especificado.',
  },
  {
    question: '¿Cuál es el propósito del método querySelectorAll en JavaScript?',
    answer: 'El método querySelectorAll se usa para seleccionar todos los elementos que coincidan con un selector CSS especificado y devuelve una NodeList de los elementos encontrados.',
  },

];