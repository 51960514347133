import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import DescriptionCurso from './views/descriptionCurso';
import BlogDescripction from './views/blogDescripction';
import FaqList from './views/faqComponent/faqList';
import Home from './Home';
import Layout from './components/Layout';
import "./globals.css"
import { initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import PersonalPortfolio from './views/personalPortfolio';
import FaqQuiz from './views/fazQuizz/default';
import WebDesignTips from './views/WebDesignTips/WebDesignTips';
import NavbarViews from './components/NavbarViews';
import ArticleComponent from './views/articleComponent';
import AssessmentBlog from './views/codeChallenges/AssessmentBlog';
import InteractiveTrends from './views/tendencyInteract/tendencyInteract';
import HeroFuturia from './components/Futuria/HeroFuturia';
import ToolsIaFuturia from './components/Futuria/ToolsIaFuturia';
import BlogServerComponent from './views/blogServerComponent';
// Configura tu proyecto de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBYcy0M50zp_dNvXMu-fl4WjS6Jnd5eyGE",
  authDomain: "comentariosblog-87704.firebaseapp.com",
  projectId: "comentariosblog-87704",
  storageBucket: "comentariosblog-87704.appspot.com",
  messagingSenderId: "275874227001",
  appId: "1:275874227001:web:702aaff10c75755000bee8",
  measurementId: "G-YDYYBL5SSY"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); 

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout><Home/></Layout>,
  },
  {
    path: "/curso/:title/",
    element: <Layout><NavbarViews/><DescriptionCurso/></Layout>,
  },
  {
    path: "/blog/:title/",
    element: <Layout><NavbarViews/><BlogDescripction/></Layout>,
  },
  {
    path: "/:title/",
    element: <Layout><NavbarViews/><FaqList/></Layout>,
  },
  {
    path: "/faq-javascript-semis-senior/",
    element: <Layout><NavbarViews/><FaqQuiz/></Layout>,
  },
  {
    path: "/web-design-tips/",
    element: <Layout><NavbarViews/><WebDesignTips /></Layout>,
  },
  {
    path: "/personal-portfolio/",
    element: <Layout><NavbarViews/><PersonalPortfolio /></Layout>,
  },
  {
    path: "/article-component/",
    element: <Layout><NavbarViews/><ArticleComponent /></Layout>,
  },
  {
    path: "/assessment-blog/",
    element: <Layout><NavbarViews/><AssessmentBlog /></Layout>,
  },
  {
    path: "/tendencias-interactividad-diseño-web/",
    element: <Layout><NavbarViews/><InteractiveTrends /></Layout>,
  },
  {
    path: "/futuria-ia/",
    element: <><NavbarViews/><HeroFuturia /><ToolsIaFuturia /></>,
  },
  {
    path: "/diferencia-ssr-sc/",
    element: <Layout><NavbarViews/><BlogServerComponent /></Layout>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);