import React from 'react';
import { BuildingOfficeIcon, ClockIcon, ArrowDownTrayIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';

export const ArticleCard = ({
  title,
  summary,
  readTime,
  difficulty,
  company,
  image,
  pdfUrl
}) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
      
      
      <div className="p-6">
        <div className="flex justify-between items-start mb-2">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200">
            <BuildingOfficeIcon className="h-4 w-4 mr-1" />
            {company}
          </span>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200">
            <ArrowTrendingUpIcon className="h-4 w-4 mr-1" />
            {difficulty}
          </span>
        </div>
        
        <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2 line-clamp-2">
          {title}
        </h3>
        
        <p className="text-gray-600 dark:text-gray-300 mb-4 flex-grow">
          {summary}
        </p>
        
        <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-4">
          <ClockIcon className="h-4 w-4 mr-1" />
          {readTime}
        </div>
        
        <button 
          onClick={() => window.open(pdfUrl, '_blank')}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center"
        >
          <ArrowDownTrayIcon className="h-4 w-4 mr-2" />
          Descargar para practicar 
        </button>
      </div>
    </div>
  );
};
