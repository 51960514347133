import React from 'react';
import LearningObjectives from './LearningObjectives';
import CourseContent from './CourseContent';
import TeachInfo from './TeachInfo';
import CourseInclusive from './CourseInclusive';
import TitleinfoCoursesTemplate from './TitleinfoCoursesTemplate';
import VideoCourse from './VideoCourse';

const TemarioCourses = ({ courseData }) => {
  return (
    <div className="bg-gray-900 text-white min-h-screen p-6">

      {/* Layout principal */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* VideoCourse Component */}
        <div className="md:col-span-2">
          <VideoCourse courseData={courseData.levelCourse} />
        </div>

        {/* InfoCourse Component */}
        <div className="flex flex-col gap-6">
          <CourseInclusive courseData={courseData.infoCourseInclusive} />
          <TeachInfo />
        </div>

        {/* ContentCourse Component */}
        <div className="md:col-span-3">
          <TitleinfoCoursesTemplate courseData={courseData} />
          <LearningObjectives objectives={courseData.learningObjectives} />
          <CourseContent content={courseData.courseContent} />
        </div>
      </div>
    </div>
  );
};

export default TemarioCourses;