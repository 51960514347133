import PruebaDjangoEmails from '../../assets/pdfFiles/PruebaDjangoEmails.pdf';
import Evaluacion360Guide from '../../assets/pdfFiles/Evaluacion360Guide.pdf';
import JavascriptChallenges from '../../assets/pdfFiles/Prueba-tecnica-hb.pdf';
import DeveloperFullStackTestEspañol from '../../assets/pdfFiles/DeveloperFullStackTestEspañol.pdf';
import PruebaPHP from '../../assets/pdfFiles/PruebaPHPMySQL.pdf';
import FullStackJs from '../../assets/pdfFiles/FullStackJs.md';
import JuniorJava from '../../assets/pdfFiles/JuniorJava.pdf';
import practiceFrontend from '../../assets/pdfFiles/practice-frontend.pdf';
import RickAndMorty from '../../assets/pdfFiles/Prueba_Tecnica_para_Frontend_Mid.pdf';
import podscastChallanger from '../../assets/pdfFiles/Prueba_tecnica_-_Front_End_Developer_2.pdf';

export const articles = [
    {
        id: 1,
        title: 'Creación de un Sistema de Gestión de Campañas de Email en Django',
        summary: 'Aprende a desarrollar un sistema en Django para gestionar campañas de email, que incluye autenticación de usuarios, listas de contactos, creación de campañas y envío de correos. Este proyecto también aborda buenas prácticas en validación de datos y diseño de interfaz responsiva.',
        readTime: '20 min read',
        difficulty: 'Semi-Senior',
        company: 'Full Stack Developer',
        pdfUrl: PruebaDjangoEmails
    },      
    {
        id: 2,
        title: 'Desarrollo de una Aplicación  en React y Node.js',
        summary: 'Construcción de una aplicación web completa para realizar evaluaciones de empleados remotos. Incluye autenticación, gestión de usuarios, creación y análisis de evaluaciones, visualización de datos y roles específicos de acceso.',
        readTime: '30 min read',
        difficulty: 'Semi-Senior',
        company: 'Full Stack Developer',
        pdfUrl: Evaluacion360Guide
    },
    {
        id: 3,
        title: 'Ejercicios de Programación en JavaScript',
        summary: 'Un conjunto de ejercicios prácticos de JavaScript para poner a prueba habilidades en agrupar nombres por inicial, eliminar palabras duplicadas, calcular diferencias de fechas y manejar promesas y solicitudes asincrónicas.',
        readTime: '15 min de lectura',
        difficulty: 'Junior',
        company: 'Desarrollador Frontend',
        pdfUrl: JavascriptChallenges
    },
    {
        id: 4,
        title: 'Desarrollo de Aplicación con React y GraphQL',
        summary: 'Desarrolla una aplicación frontend usando React 18, GraphQL, y React Router DOM, que permita listar y gestionar personajes de la serie Rick y Morty, con funcionalidades de ordenamiento, comentarios y favoritos. Backend con Express, Sequelize y Redis para optimización de consultas.',
        readTime: '25 min de lectura',
        difficulty: 'Junior Avanzado',
        company: 'Desarrollo Full Stack',

        pdfUrl: DeveloperFullStackTestEspañol
    },  
    {
        id: 5,
        title: 'Sistema Básico de Gestión de Eventos MERN',
        summary: 'Desarrolla un sistema web para gestionar la inscripción, control de acceso y asignación de roles de los asistentes a un evento. El sistema incluirá notificaciones segmentadas, gestión de roles por administradores y un archivo README detallado.',
        readTime: '30 min de lectura',
        difficulty: 'Junior',
        company: 'Desarrollador Frontend',
        pdfUrl: 'https://maxiproyect.notion.site/Prueba-T-cnica-Sistema-B-sico-de-Gesti-n-de-Eventos-7868d71013794440aafa7adab0424592'
    },  
    {
        id: 6,
        title: 'Desarrollo de una aplicación web con PHP y MySQL',
        summary: 'Desarrolla un sistema web en PHP que gestione un sistema de clima personalizado y sistema de autenticación de usuarios.',
        readTime: '30 min de lectura',
        difficulty: 'Junior Avanzado',
        company: 'Desarrollador backend',
        pdfUrl: PruebaPHP
    },
    {
        id: 7,
        title: 'Sitio web de películas con Next.js',
        summary: 'Crea un reproductor de videos con soporte para contar visualizaciones y "me gusta". Implementa una API para manejar los datos, con el uso de NextJS, TailwindCSS, ShadCN y tRPC. El reproductor debe incluir características adicionales como controles avanzados de video y una interfaz de usuario bien diseñada.',
        readTime: '45 min de lectura',
        difficulty: 'Junior',
        company: 'Desarrollador Frontend',
        pdfUrl: 'https://vidext-pro.notion.site/Prueba-t-cnica-Junior-aa69b3adc7a6441ab1c83c9153f490d3'
    },
    {
        id: 8,
        title: 'Prueba Asíncrona de Desarrollador Full Stack JavaScript',
        summary: 'Desarrolla una aplicación web para gestionar productos (teléfonos móviles) usando Node.js en el backend y React en el frontend. La aplicación debe incluir funcionalidades CRUD para productos, con autenticación, carga de productos de prueba y eliminación masiva de productos, todo integrado en Vercel. Implementa una API RESTful y servicios externos con Swagger, además de un procedimiento en segundo plano para eliminar productos.',
        readTime: '60 min de lectura',
        difficulty: 'Semi-Senior',
        company: 'Desarrollador Full Stack',
        pdfUrl: FullStackJs
    },
    {
        id: 9,
        title: 'Examen Sustitutorio: Desarrollo de Software en Java',
        summary: "Desarrolla una aplicación de consola en Java para gestionar proyectos de software. La aplicación debe incluir funcionalidades para la gestión de tareas, aplicar patrones de diseño como Observer y Strategy, y medir la cohesión y acoplamiento del código. Además, se debe implementar seguridad, pruebas unitarias, y seguir principios SOLID. El ejercicio está dividido en dos sprints.",
        readTime: "120 min de lectura",
        difficulty: "Senior",
        company: "Desarrollador Backend",
        pdfUrl: JuniorJava
    },
    {
        id: 10,
        title: 'Desarrollo MERN de email verificacion',
        summary: "Desarrolla una aplicacion para loggear un usuario.Este proyecto tiene que tener validaciones de los campos, verificacion doble autenticacion y seguir la UI modelo",
        readTime: "40 min de lectura",
        difficulty: "Junior Advance",
        company: "Desarrollador Full Stack",
        pdfUrl: practiceFrontend
    },
    {
        id: 11,
        title: 'Sitio Web para Fans de Rick & Morty',
        summary: "Construye una aplicación moderna con React o Svelte para explorar personajes y ubicaciones de Rick & Morty. Incluye un sistema de favoritos, desplazamiento infinito y autenticación para gestionar la lista de favoritos. Usa TypeScript y despliega la aplicación en Vercel, Netlify o GitHub Pages.",
        readTime: "40 min de lectura",
        difficulty: "Junior",
        company: "Desarrollador Frontend",
        pdfUrl: RickAndMorty
    },
    {
        id: 12,
        title: 'Aplicación de Podcasts Musicales - Prueba Técnica Frontend',
        summary: "Desarrolla una aplicación SPA con vistas para explorar y reproducir podcasts musicales. Incluye una lista de los 100 podcasts más populares, detalles de cada podcast y un reproductor para los episodios. La aplicación debe manejar almacenamiento local, filtrado en tiempo real y navegación sin recarga de página.",
        readTime: "45 min de lectura",
        difficulty: "Junior Avanzado",
        company: "Desarrollador Frontend",
        pdfUrl: podscastChallanger
    }
    
  ];