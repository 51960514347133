import { useState, useEffect, useCallback } from 'react';
import { filterFaqs, getInitialFaqs } from '../faqComponent/utils/faqUtils';

export const usePredictiveSearch = (onSearchResult) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const updateSuggestions = useCallback(() => {
    if (searchTerm.length > 0) {
      const filteredSuggestions = filterFaqs(searchTerm).slice(0, 5);
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
      onSearchResult(getInitialFaqs());
    }
  }, [searchTerm, onSearchResult]);

  useEffect(() => {
    updateSuggestions();
  }, [updateSuggestions]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSuggestionClick = (question) => {
    setSearchTerm(question);
    setShowSuggestions(false);
    onSearchResult(filterFaqs(question, true));
  };

  const handleInputFocus = () => {
    if (searchTerm.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleInputBlur = () => {
    // Pequeño retraso para permitir que el clic en la sugerencia se procese
    setTimeout(() => setShowSuggestions(false), 200);
  };

  return { 
    searchTerm, 
    suggestions, 
    showSuggestions,
    handleSearch, 
    handleSuggestionClick,
    handleInputFocus,
    handleInputBlur
  };
};