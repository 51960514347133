import React, { useState } from 'react';
import FaqItemChildren from './faqItemChildren';
import Search from '../../components/Search';
import { getInitialFaqs } from './utils/faqUtils';
import ListTitleFaq from './ListTitleFaq'; 
import { faqs } from './utils/faqData';

const FaqList = () => {
  const [displayedFaqs, setDisplayedFaqs] = useState(getInitialFaqs());

  const handleSearchResult = (results) => {
    setDisplayedFaqs(results);
  };

  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-center mb-8 mt-12">Preguntas típicas de Javascript</h1>
        <Search onSearchResult={handleSearchResult} />
        
        

        <h2 className="text-2xl font-semibold text-yellow-600 mb-6">
          {displayedFaqs.length !== getInitialFaqs().length ? 'Resultados de búsqueda' : 'Las preguntas más buscadas...'}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {displayedFaqs.map((faq, index) => (
            <FaqItemChildren key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>

        <div className="mb-8">
          <ListTitleFaq faqs={faqs} />
        </div>
      </div>
    </div>
  );
};

export default FaqList;