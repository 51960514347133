import React, { useState } from 'react';

const ListTitleFaq = ({ faqs }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAnswer = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold text-yellow-600 mb-4">Lista de Preguntas Frecuentes</h2>
      <ul className="space-y-4">
        {faqs.map((faq, index) => (
          <li key={index} className="border-b border-gray-200 pb-4">
            <div 
              className="flex justify-between items-center cursor-pointer hover:bg-gray-100 p-2 rounded-md transition-colors duration-200"
              onClick={() => toggleAnswer(index)}
            >
              <span className="font-medium text-gray-700">{index + 1}. {faq.question}</span>
              <span className="text-yellow-500">{expandedIndex === index ? '▲' : '▼'}</span>
            </div>
            {expandedIndex === index && (
              <div className="mt-2 pl-6 text-gray-600">
                {faq.answer}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListTitleFaq;