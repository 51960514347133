import React from "react";

const ArticleComponent = () => {
  return (
    <div className="max-w-screen-lg mx-auto p-6 sm:p-12">
      <div
        className="bg-cover bg-center text-center overflow-hidden rounded-lg shadow-md"
        style={{
          minHeight: "400px",
          backgroundImage:
            "url('https://cdn.pixabay.com/photo/2015/06/24/15/45/code-820275_960_720.jpg')",
        }}
        title="Frontend Developer con UX/UI"
      ></div>

      <div className="bg-white p-6 mt-5 rounded-lg shadow-lg">
        <h1
          className="text-3xl font-bold text-gray-800 mb-4"
          style={{ fontFamily: "Plus Jakarta Sans, sans-serif" }}
        >
          Ventajas de Ser un Frontend Developer con Conocimientos en Diseño UX/UI
        </h1>
        <p
          className="text-gray-600 mb-6"
          style={{ fontFamily: "Merriweather, serif", fontWeight: 300 }}
        >
          En el mundo de la tecnología, el perfil de Frontend Developer con
          conocimientos de diseño UX/UI está cada vez más demandado y es un
          verdadero game changer en cualquier proyecto digital. ¿Por qué? Porque
          combina lo mejor de dos mundos esenciales: la habilidad técnica para
          desarrollar interfaces y la sensibilidad para diseñar experiencias
          intuitivas, atractivas y funcionales.
        </p>
        <p
          className="text-gray-600 mb-6"
          style={{ fontFamily: "Merriweather, serif", fontWeight: 300 }}
        >
          Si querés saber por qué tener habilidades en diseño UX/UI puede
          transformar tu carrera como frontend, ¡seguí leyendo!
        </p>

        <div className="space-y-8">
          <section>
            <h2
              className="text-2xl font-semibold text-gray-800 mb-2"
              style={{
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontWeight: 600,
              }}
            >
              1. Entendés las necesidades del usuario desde el código
            </h2>
            <p
              className="text-gray-600"
              style={{ fontFamily: "Merriweather, serif", fontWeight: 300 }}
            >
              Como frontend developer, tu rol principal es hacer que la
              interfaz funcione y luzca impecable. Pero, al sumar conocimientos
              de UX/UI, podés ir más allá y desarrollar con una comprensión
              profunda de las expectativas y necesidades del usuario final.
            </p>
            <blockquote className="border-l-4 text-base italic leading-8 my-5 p-5 text-indigo-600">
              "Un desarrollador que entiende al usuario desde el código crea
              experiencias que resuenan y conectan."
            </blockquote>
          </section>

          <section>
            <h2
              className="text-2xl font-semibold text-gray-800 mb-2"
              style={{
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontWeight: 600,
              }}
            >
              2. Más comunicación, menos confusión
            </h2>
            <p
              className="text-gray-600"
              style={{ fontFamily: "Merriweather, serif", fontWeight: 300 }}
            >
              Al tener experiencia en UX/UI, vos actuás como un puente entre
              diseñadores y desarrolladores, anticipándote a posibles problemas
              de diseño y proponiendo ajustes que faciliten la implementación
              sin perder la esencia.
            </p>
            <blockquote className="border-l-4 text-base italic leading-8 my-5 p-5 text-indigo-600">
              "Ser el puente entre diseño y desarrollo reduce los malentendidos
              y acelera la creación de productos excepcionales."
            </blockquote>
          </section>

          <section>
            <h2
              className="text-2xl font-semibold text-gray-800 mb-2"
              style={{
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontWeight: 600,
              }}
            >
              3. Un portfolio que deslumbra a primera vista
            </h2>
            <p
              className="text-gray-600"
              style={{ fontFamily: "Merriweather, serif", fontWeight: 300 }}
            >
              Cuando tenés habilidades en UX/UI, tus proyectos son más que
              código funcional. Podés diseñar y desarrollar proyectos que
              cautivan a primera vista y se destacan del montón.
            </p>
            <blockquote className="border-l-4 text-base italic leading-8 my-5 p-5 text-indigo-600">
              "Un portfolio bien diseñado abre puertas y capta la atención de
              quienes buscan talento que combine técnica y creatividad."
            </blockquote>
          </section>

          <section>
            <h2
              className="text-2xl font-semibold text-gray-800 mb-2"
              style={{
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontWeight: 600,
              }}
            >
              4. Visión estratégica en proyectos digitales
            </h2>
            <p
              className="text-gray-600"
              style={{ fontFamily: "Merriweather, serif", fontWeight: 300 }}
            >
              Los conocimientos en UX/UI te permiten anticipar problemas en la
              fase de diseño y proponer mejoras que optimizan el desarrollo y
              mejoran el rendimiento de la aplicación final.
            </p>
            <blockquote className="border-l-4 text-base italic leading-8 my-5 p-5 text-indigo-600">
              "La visión estratégica en diseño es clave para proyectos exitosos
              que cumplen objetivos de negocio y usuario."
            </blockquote>
          </section>

          <section>
            <h2
              className="text-2xl font-semibold text-gray-800 mb-2"
              style={{
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontWeight: 600,
              }}
            >
              5. Autonomía y adaptabilidad: la clave para crecer
            </h2>
            <p
              className="text-gray-600"
              style={{ fontFamily: "Merriweather, serif", fontWeight: 300 }}
            >
              Ser un frontend developer con conocimientos en UX/UI significa
              tener más control sobre el producto final y ser un perfil más
              versátil.
            </p>
            <blockquote className="border-l-4 text-base italic leading-8 my-5 p-5 text-indigo-600">
              "La autonomía en diseño y desarrollo permite un flujo de trabajo
              más ágil y eficiente."
            </blockquote>
          </section>

          <section>
            <h2
              className="text-2xl font-semibold text-gray-800 mb-2"
              style={{
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontWeight: 600,
              }}
            >
              6. Ampliás tus posibilidades en el mercado laboral
            </h2>
            <p
              className="text-gray-600"
              style={{ fontFamily: "Merriweather, serif", fontWeight: 300 }}
            >
              Los perfiles de frontend con conocimientos en UX/UI están cada vez
              más solicitados. Esto abre oportunidades en startups, agencias y
              grandes corporaciones.
            </p>
            <blockquote className="border-l-4 text-base italic leading-8 my-5 p-5 text-indigo-600">
              "Las habilidades duales en frontend y UX/UI son una gran ventaja
              competitiva en el mercado."
            </blockquote>
          </section>

          <section>
            <h2
              className="text-2xl font-semibold text-gray-800 mb-2"
              style={{
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontWeight: 600,
              }}
            >
              Conclusión
            </h2>
            <p
              className="text-gray-600"
              style={{ fontFamily: "Merriweather, serif", fontWeight: 300 }}
            >
              Tener habilidades de frontend y, a la vez, conocimientos en diseño
              UX/UI, te permite ver el panorama completo, integrar la estética y
              la funcionalidad en cada proyecto y destacarte en un mercado en
              constante evolución. Es una inversión en tu carrera que puede
              abrirte puertas y brindarte proyectos que verdaderamente disfrutes
              y te desafíen a ser mejor cada día.
            </p>
          </section>
        </div>

       
      </div>
    </div>
  );
};

export default ArticleComponent;
