import React from 'react';

const trendsData = [
  {
    title: "Infinite Scroll (Desplazamiento Infinito)",
    description: "El infinite scroll permite a los usuarios continuar desplazándose hacia abajo sin tener que cargar nuevas páginas, ya que el contenido se va cargando automáticamente a medida que se desplazan.",
    benefits: "Este enfoque mantiene al usuario en la página y reduce las cargas manuales. Es popular en redes sociales y sitios densos en contenido.",
    advice: "Usá carga diferida (lazy loading) para cargar contenido solo cuando el usuario realmente lo necesite, evitando sobrecargar la página y mejorando el rendimiento."
  },
  {
    title: "Microinteracciones (Microanimations)",
    description: "Las microinteracciones son pequeños efectos visuales o animaciones en respuesta a acciones específicas del usuario.",
    benefits: "Mejoran la experiencia de usuario al dar una respuesta instantánea y visual, haciendo el sitio más intuitivo.",
    advice: "Mantené las microinteracciones breves y relevantes, evitando sobrecargar el sitio con animaciones que afecten la velocidad."
  },
  {
    title: "Carga Diferida de Imágenes y Videos (Lazy Loading)",
    description: "Permite cargar solo las imágenes y videos visibles en el viewport del usuario, dejando el contenido restante para más tarde.",
    benefits: "Optimiza el rendimiento del sitio y mejora el tiempo de carga inicial.",
    advice: "Usá el atributo loading='lazy' en imágenes HTML y configurá scripts de carga diferida en otros elementos multimedia."
  },
  {
    title: "Elementos de Navegación Pegajosa (Sticky Navigation)",
    description: "Una barra de navegación que permanece visible mientras el usuario se desplaza.",
    benefits: "Facilita la navegación rápida entre secciones, manteniendo visibles elementos de contacto.",
    advice: "Limitá el uso para elementos esenciales, evitando que ocupe demasiado espacio en móviles."
  },
  {
    title: "Botones de Acción Flotantes (Floating Action Buttons)",
    description: "Botones en la esquina inferior para acciones rápidas como chat o carrito.",
    benefits: "Mantienen las acciones importantes a mano sin obstruir la pantalla.",
    advice: "Mantené los botones simples y con una función clara, evitando múltiples botones flotantes."
  },
  {
    title: "Interacción Basada en Gestos",
    description: "Permite a los usuarios deslizar, pellizcar o tocar para navegar y realizar acciones en dispositivos táctiles.",
    benefits: "Ofrece una experiencia inmersiva, aprovechando las capacidades táctiles.",
    advice: "Aseguráte de que los gestos sean intuitivos y no interfieran con las acciones comunes."
  },
  {
    title: "Desplazamiento Animado (Scroll-Triggered Animations)",
    description: "Animaciones activadas al desplazarse, enfatizando contenido visualmente.",
    benefits: "Mejora la narrativa visual, captando la atención del usuario.",
    advice: "Usá estas animaciones moderadamente, solo cuando aporten valor al contenido."
  }
];

const TrendItem = ({ trend }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:scale-105">
    <h3 className="text-2xl font-semibold text-gray-900 mb-4">{trend.title}</h3>
    <p className="text-gray-800 text-sm">
      <strong className="font-medium">¿Qué es?:</strong> {trend.description}
    </p>
    <p className="text-gray-800 text-sm mt-3">
      <strong className="font-medium">Ventajas:</strong> {trend.benefits}
    </p>
    <p className="text-gray-800 text-sm mt-3">
      <strong className="font-medium">Consejo de Implementación:</strong> {trend.advice}
    </p>
  </div>
);

const InteractiveTrends = () => (
  <div className="max-w-3xl mx-auto px-6 py-12">
    <h2 className="text-3xl font-bold text-center text-gray-900 mb-6 mt-20">
      Tendencias de Interactividad en Diseño Web para 2024
    </h2>
    <p className="text-gray-700 text-center mb-12">
      La interactividad es clave para retener a los usuarios y hacer que se sientan involucrados y conectados con el contenido. En 2024, esta interactividad evoluciona, optimizando tanto la experiencia del usuario como el rendimiento del sitio. Aquí exploramos algunas de las técnicas de interactividad que están en auge y cómo podés implementarlas en tus proyectos.
    </p>
    <div className="space-y-8">
      {trendsData.map((trend, index) => (
        <TrendItem key={index} trend={trend} />
      ))}
    </div>
  </div>
);

export default InteractiveTrends;
