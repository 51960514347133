import React from 'react';
import { ArticleCard } from './ArticleCard';
import { articles } from './data';
import { BookOpenIcon } from '@heroicons/react/24/solid';

function AssessmentBlog() {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 mt-16">
          <header className="py-12 px-4 text-center">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4 flex items-center justify-center gap-2">
            <BookOpenIcon className="h-8 w-8 text-blue-600" /> {/* Update the icon component */}
            Prepárate para entrevistas técnicas
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Aca vas a encontrar examenes de entrevistas técnicas de diferentes compañias y de todo los lenguajes de programación.
          </p>
          </div>
        </header>
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {articles.map((article) => (
              <ArticleCard
                key={article.id}
                {...article}
              />
            ))}
          </div>
        </main>
      </div>
    );
  }

export default AssessmentBlog;