import CursorIA from '../../assets/Futuria/Cursor-AI.png';
import IdeogramAI from '../../assets/Futuria/ideogramAI.png';
import FreeLogoMaker from '../../assets/Futuria/mflm-share-image-1.png';
import BoltNew from '../../assets/Futuria/boltnew.jpeg';
import VercelAi from '../../assets/Futuria/vercelAI.png';
import notionAI from '../../assets/Futuria/notionAI.png';
import GrepApp from '../../assets/Futuria/grepapp.png';
import ShotSo from '../../assets/Futuria/mockUpShot.jpeg';
import Iloveimg from '../../assets/Futuria/iloveimg.jpeg';
import Unsplash from '../../assets/Futuria/unsplash.png';
import Pexels from '../../assets/Futuria/pexels.png';
import Pixabay from '../../assets/Futuria/pixabay.png';
import aosEfectoVisuales from '../../assets/Futuria/aosEfectoVisuales.png';
import animatecss from '../../assets/Futuria/animatecss.png';
import librerayOpenSource from '../../assets/Futuria/librerayOpenSource.png';
const callouts = [
    {
      name: 'Cursor AI',
      description: 'Compilador de codigo con inteligencia artificial, predictivo, documentacion automatica, lectura de contexto,etc.  ',
      imageSrc: CursorIA,
      imageAlt: 'Cursor IA',
      href: 'https://www.cursor.com/',
    },
    {
      name: 'Ideogram-AI',
      description: 'Herramienta basada en inteligencia artificial que permite a los usuarios generar imágenes a partir de descripciones de texto.',
      imageSrc: IdeogramAI,
      imageAlt: 'herramienta basada en inteligencia artificial que permite a los usuarios generar imágenes a partir de descripciones de texto.',
      href: 'https://www.ideogram.ai/',
    },
    {
      name: 'Free Logo Maker',
      description: 'Herramienta en línea que permite a los usuarios crear y descargar logotipos de forma gratuita.',
      imageSrc: FreeLogoMaker,
      imageAlt: 'Herramienta en línea que permite a los usuarios crear y descargar logotipos de forma gratuita.',
      href: 'https://myfreelogomaker.com/onboarding',
    },
    {
      name: 'Bolt.new',
      description: 'Plataforma innovadora que permite crear, ejecutar, depurar y desplegar aplicaciones web de pila completa en tiempo real directamente desde el navegador. ',
      imageSrc: BoltNew,
      imageAlt: 'Plataforma innovadora que permite crear, ejecutar, depurar y desplegar aplicaciones web de pila completa en tiempo real directamente desde el navegador. ',
      href: 'https://bolt.new/',
    },
    {
      name: 'Vercel AI',
      description: 'Plataforma que ofrece herramientas de inteligencia artificial para ayudar a los desarrolladores a crear aplicaciones web más eficientes y efectivas.',
      imageSrc: VercelAi,
      imageAlt: 'Plataforma que ofrece herramientas de inteligencia artificial para ayudar a los desarrolladores a crear aplicaciones web más eficientes y efectivas.',
      href: 'https://vercel.com/ai',
    },
    {
      name: 'Notion AI',
      description: 'una plataforma de productividad y gestión de proyectos. Ayuda a los usuarios a agilizar tareas como generar contenido, resumir textos, proponer ideas, redactar correos y organizar información',
      imageSrc: notionAI,
      imageAlt: 'una plataforma de productividad y gestión de proyectos. Ayuda a los usuarios a agilizar tareas como generar contenido, resumir textos, proponer ideas, redactar correos y organizar información',
      href: 'https://www.notion.so/ai',
    },
    {
      name: 'Grep App',
      description: 'Encontrar código más rápido e implementarlo en un repositorio de GitHub.',
      imageSrc: GrepApp,
      imageAlt: 'Encontrar código más rápido e implementarlo en un repositorio de GitHub.',
      href: 'https://grep.app/',
    },
    {
      name: 'Shot so',
      description: 'Permite generar representaciones profesionales de diseños o proyectos en entornos simulados, ideales para presentaciones, portafolios, o compartir ideas de manera clara y visual en redes sociales o con equipos de trabajo.',
      imageSrc: ShotSo,
      imageAlt: 'Permite generar representaciones profesionales de diseños o proyectos en entornos simulados, ideales para presentaciones, portafolios, o compartir ideas de manera clara y visual en redes sociales o con equipos de trabajo.',
      href: 'https://shots.so/',
    },
    {
      name: 'Iloveimg',
      description: 'Herramientas online para la edición de imágenes',
      imageSrc: Iloveimg,
      imageAlt: 'Herramientas online para la edición de imágenes',
      href: 'https://www.iloveimg.com/es',
    },
    {
      name: 'Unsplash',
      description: 'Plataforma de fotografías gratuitas para uso comercial y no comercial',
      imageSrc: Unsplash,
      imageAlt: 'Plataforma de fotografías gratuitas para uso comercial y no comercial',
      href: 'https://unsplash.com/es',
    },
    {
      name: 'Pexels',
      description: 'Plataforma de fotografías gratuitas para uso comercial y no comercial',
      imageSrc: Pexels,
      imageAlt: 'Plataforma de fotografías gratuitas para uso comercial y no comercial',
      href: 'https://www.pexels.com/es-es/',
    },
    {
      name: 'Pixabay',
      description: 'Plataforma de fotografías gratuitas para uso comercial y no comercial',
      imageSrc: Pixabay,
      imageAlt: 'Plataforma de fotografías gratuitas para uso comercial y no comercial',
      href: 'https://pixabay.com/',
    },
    {
      name: 'Efectos Visuales',
      description: 'Repositorio de efectos visuales gratuitos con codigo fuente',
      imageSrc: aosEfectoVisuales,
      imageAlt: 'Repositorio de efectos visuales gratuitos con codigo fuente',
      href: 'https://michalsnik.github.io/aos/',
    },
    {
      name: 'Animate CSS',
      description: 'Pagina we de animaciones CSS',
      imageSrc: animatecss,
      imageAlt: 'Pagina de animaciones CSS',
      href: 'https://animate.style/',
    },
    {
      name: 'Componentes UI',
      description: 'Pagina web de componentes con tailwind, react, html ,css y figma.',
      imageSrc: librerayOpenSource,
      imageAlt: 'Pagina web de componentes con tailwind, react, html ,css y figma.',
      href: 'https://uiverse.io/',
    },
  ]
  
  export default function ToolsIaFuturia() {
    return (
      <div className="bg-gradient-to-br from-[#0B0B2B] via-[#1A1A4F] to-[#2D1B4E]" id="herramientas">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
            <h2 className="text-2xl font-bold text-white">HERRAMIENTAS</h2>
  
            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative">
                  <img
                    alt={callout.imageAlt}
                    src={callout.imageSrc}
                    className="w-full rounded-lg bg-white object-cover group-hover:opacity-75 max-sm:h-80 sm:aspect-[2/1] "
                  />
                  <h3 className="mt-6 text-sm text-white">
                    <a href={callout.href} target="_blank">
                      <span className="absolute inset-0" />
                      {callout.name}
                    </a>
                  </h3>
                  <p className="text-base font-semibold text-white">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  