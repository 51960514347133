import React from 'react';

const TitleinfoCoursesTemplate = ({ courseData }) => {
  return (
    <div className="mt-6 bg-box border border-line rounded-3xl p-5">
    <span className="inline-block bg-[#16A349] text-white px-4 py-2 rounded-full text-sm mb-6">
      Próximamente más clases disponibles
    </span>
    <h1 className="text-3xl font-bold mb-6">
      {courseData.CourseTitleHero}
    </h1>
    <div>
      <h2 className="text-xl font-bold mb-2">Descripción</h2>
      <p className="text-base">
        {courseData.descriptionHero}
      </p>
    </div>
  </div>
  );
};

export default TitleinfoCoursesTemplate;