import React from 'react';
import PropTypes from 'prop-types';

const FaqItemChildren = ({ question, answer }) => (
  <div className="bg-white shadow-md rounded-lg p-6 mb-4">
    <h3 className="text-xl font-bold text-yellow-600 mb-2">{question}</h3>
    <p className="text-gray-600">{answer}</p>
  </div>
);

FaqItemChildren.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default FaqItemChildren;