import React from 'react';

const CourseInclusive = ({ courseData }) => {
  return (
    <div className="bg-gray-900 text-white rounded-lg p-6 shadow-md max-w-sm ">
      <h3 className="text-lg font-bold mb-4">El curso incluye:</h3>
      <div className="space-y-4">
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132a1 1 0 00-1.609.794v4.34a1 1 0 001.609.794l3.197-2.132a1 1 0 000-1.588z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19a7 7 0 110-14 7 7 0 010 14z" />
          </svg>
          <p className="text-sm">Horas de video: <span className="font-bold">{courseData.HoursVideo}</span></p>
        </div>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
          <p className="text-sm">Lenguaje: <span className="font-bold">Español</span></p>
        </div>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          <p className="text-sm">Lecciones: <span className="font-bold">{courseData.Lessons}</span></p>
        </div>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M3 6h18M3 14h18m-6 4h6" />
          </svg>
          <p className="text-sm">Nivel: <span className="font-bold">{courseData.Level}</span></p>
        </div>
      </div>
      <a 
        className="mt-6 w-full bg-green-500 text-white py-2 rounded-lg font-bold text-sm hover:bg-green-600 inline-block text-center"
        href="https://kick.com/educacioncristian" 
        target="_blank" 
        rel="noopener noreferrer">
        Suscríbete en Kick
      </a>
      <p className="text-xs text-gray-400 text-center mt-2">
        Acceso al curso con o sin la suscripción
      </p>
    </div>
  );
};

export default CourseInclusive;
